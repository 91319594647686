<template>
    <full-page title="Past Events">

<h2>The Scottish 4000s</h2>        
<p>After a successful outing for the Surrey Hills Running Club at the Welsh 3000s in 2020 it was decided that a tougher challenge was in order and the Scottish 4000s sounded like a logical next step for 2021.</p>
<p>The Scottish 4000-foot summits are on two different mountain ranges, the Lochaber range near Fort William, including Ben Nevis, and the Cairngorms near Aviemore with about 80km of very remote terrain between them and few opportunities to resupply and even fewer to drop out. This was going to be a much bigger undertaking than the Welsh trip. </p>
<p>Mark started researching the route and plotted a rough draft on OS maps coming in at 140km with 5700m of climbing. The route would start at Fort William and finish at the Cairngorm youth hostel. The only road crossing on the route would be at Dalwhinnie, roughly half way. This was a long way to go self-supported.  Mark found two further support points: Corrour Station at 34km with a restaurant and Glenfeshie at 100km where we could setup a tent containing supplies. This split the <a href="https://osmaps.ordnancesurvey.co.uk/route/9321242/S4000sV4">final route</a> into four roughly equal sections.</p>
<p><img src="img/s4000-1.png" class="event-image">
</p>
<table><tbody><tr><td><strong>ID</strong></td><td><strong>Description</strong></td><td><strong>Distance (km)</strong></td><td><strong>Elevation (m)</strong></td></tr><tr><td>Start</td><td>Fort William</td><td>0</td><td>40</td></tr><tr><td>P1</td><td>Ben Nevis</td><td>7</td><td>1345</td></tr><tr><td>P2</td><td>Carn Mor Dearg</td><td>9</td><td>1220</td></tr><tr><td>P3</td><td>Aonach Mor</td><td>11.6</td><td>1221</td></tr><tr><td>P4</td><td>Aonach Beag</td><td>13.4</td><td>1234</td></tr><tr><td>B1</td><td>Stob Coire Bealach (bonus Munro)</td><td>14.4</td><td>1048</td></tr><tr><td>CP1</td><td>Corrour Station</td><td>34.8</td><td>400</td></tr><tr><td>CP2</td><td>Dalwhinnie</td><td>71.4</td><td>350</td></tr><tr><td>CP3</td><td>Glenfeshy </td><td>102</td><td>350</td></tr><tr><td>P5</td><td>Cairn Toul</td><td>117</td><td>1291</td></tr><tr><td>P6</td><td>Sgor an Lochain Uaine (The Angel&#8217;s Peak)</td><td>118</td><td>1258</td></tr><tr><td>P7</td><td>Carn na Criche</td><td>120</td><td>1265</td></tr><tr><td>P8</td><td>Braeriach</td><td>122</td><td>1296</td></tr><tr><td>P9</td><td>Ben Macdui</td><td>127</td><td>1309</td></tr><tr><td>P10</td><td>Cairn Gorm</td><td>133</td><td>1244</td></tr><tr><td>End</td><td>Cairn Gorm Lodge</td><td>140</td><td>330</td></tr></tbody></table>
<p>Accommodation was booked  at the Cairngorm Youth Hostel for four nights giving us a little bit of flexibility for the start in case of bad weather. The plan was then to drive to Fort William and run back to the hostel. We would need at least two cars so that we could subsequently drive back to Fort William to collect the car we'd left there. </p>
<p>Next, we got some T-shirts printed as this was shaping up to be a big challenge that needed some kind of memento. </p>
<p><img src="img/s4000-2.png" class="event-image"></p>
<p>Setup prior to embarking on the route wasn't straightforward. We needed to find somewhere to leave resupply points at Dalwhinnie and Glenfeshie. Dalwhinnie was quite easy &#8211; we spoke to a restaurant, The Snack Shack, and asked if we could leave of box of supplies in case we didn't make it before closing and that was all OK. Glenfeshie was a bit more complicated as there are no roads so we had a 10km hike the day before to setup a tent hidden a little way off the trail. That took a couple of hours but did loosen up the legs a bit after the long drive up to Scotland. </p>
<h3>Fort William to Corrour Station</h3>
<p>An early alarm woke us at 4am for the drive to Fort William. Boil water for porridge and a quick coffee and then we were off, arriving at Fort William a little after 6am. By 6:30 we were on the trail with a target to finish in 35 hours.</p>
<p><img src="img/s4000-3.jpg" class="event-image"></p>
<p>The initial climb was up the tourist track to the summit of Ben Nevis. Although this was almost 4000' of climb it was easy going. The trail was busy with lots of groups taking part in Three Peaks challenges. We reached the summit after almost exactly 2 hours, having covered 7km. The summit was also very busy so we didn&#8217;t spend any time there and just pressed on to the next section.</p>
<p>The area around the summit was shattered rock, much like the summit of Scafell Pike so there wasn't a distinct path to follow and visibility was low so we just followed a bearing and soon came to an exciting ridge similar to Crib Goch. There was quite a strong wind from the south east so it wasn&#8217;t much fun on the ridge itself but there was a path a little way down on the right that felt a lot more secure.</p>
<p><img src="img/s4000-4.jpg" class="event-image"></p>
<p>The ridge took us to Carn Mor Dearg, the second 4000' summit only 2km after Ben Nevis but taking 1h15. Next was a steep descent and ascent, each 400m, to get up to Aonach Mor. Looking across the climb appeared quite intimidating but ultimately wasn&#8217;t too hard and it was a relief to make the next summit feeling strong as this looked to be similar to the steep climb we&#8217;d face in the Cairngorms up  Ben Macdui much later, so we were now confident that wouldn't be too much harder, of course by then we&#8217;d not nearly be so fresh and, looking at the map now, Ben Macdui is clearly a much bigger climb but in our heads we&#8217;d proved ourselves capable of the challenge.</p>
<p><img src="img/s4000-5.png" class="event-image"></p>
<p>Next was a relatively easy section across the tops to take in Aonach Beag and Stob Coire Bhealaich before descending to the river that would take us to Loch Trieg and Corrour Station.</p>
<p>The planned descent off Bhealaich appeared way too steep, heading straight off a buttress, so we headed a little further south to find an easier route. This seemed to work quite well although at this point Hasan started heading off down towards the river whereas the route had us staying higher up and slowly losing elevation whilst contouring round. Fortunately, we never quite lost sight of each other and did meet up just before the river.</p>
<p>The next section was again quite straightforward &#8211; just follow the river. Some sections had paths but others were quite boggy with no obvious line. Progress however was good and thoughts started to turn to a hot meal at Corrour Station, about 10 miles away. The restaurant closes at 3pm and it was now 1:30pm so we only needed to manage 4 miles an hour to get there before closing. Sounded easy enough but we did need to keep on the pace &#8211; too much walking and we'd miss it. We made it with only 5 minutes to spare. That was the first quarter (34km) done in 8h30.</p>
<p><img src="img/s4000-6.jpg" class="event-image"></p>
<p><a href="https://en.wikipedia.org/wiki/Corrour_railway_station">Corrour Station</a> is the most remote railway station in Britain and it’s hard to see why it was built as there are no public roads that lead there. It does however have a restaurant and is a great way for walkers, runners and mountain bikers to access some of the more remote parts of Scotland.</p>
<p>The restaurant was great and I ate a BLT and a chip butty &#8211; both Scottish size servings and so had plenty of fuel for the next section to Dalwhinnie. The staff were also very interested in what we were doing, not having heard of such a long trek before, and wished us well as we departed. </p>
<h3>Corrour Station to Dalwhinnie</h3>
<p>We spent an hour at the restaurant and once back outside put on fresh socks. This was our first experience of midges. Annoying but easy enough to escape from if you just kept moving.</p>
<p>There was a wide path from Corrour Station down to Lock Ossian and things remained easy until the valley up to Meall an t-Slugain, which was again very boggy with no defined path, so a bit of a slog until we climbed up to a proper path on the south east side of the valley, which took us up to the col. After that we had excellent trail pretty much all the way to Dalwhinne, arriving there in good time at about 9pm. At this point we were all feeling great as we were half way, on schedule and still feeling strong (73km, 14h30 elapsed). </p>
<h3>Dalwhinnie to Glenfeshie</h3>
<p>At Dalwhinnie we'd stashed a cool box filled with food and spare clothes. I immediately put on a long-sleeved top and leggings as the midges were about. That plus a head net seemed to be enough to keep them from biting. </p>
<p>We boiled up water and each made a hot meal. That plus sorting out kit took about an hour and then we were off again in the dark on the next flattish section to our tent at Glenfeshie.</p>
<p>The path was wide and easy and yet none of us felt like running and just hiked into the night. Easy trail continued to Loch Cuaich, after which we were on a section with no path contouring the side of a hill covered in thick heather. This was only 4 or 5km but seemed to go on forever and was difficult to negotiate in the dark, with each of us regularly falling over. At least the heather was soft and on one occasion we all just lay on our backs, switched off head torches and stared up into the dark. The lack of progress was very frustrating but consulting the map didn&#8217;t reveal any viable alternatives. Relief came when we joined with a path coming down from Carn Thomais. By the end of this section our energy was sapped completely and Glenfeshie seemed a long way away. </p>
<p><img src="img/s4000-7.png" class="event-image"></p>
<p>Once we were back on paths things were much easier. The bridge for the River Tromie crossing had been washed away but there was another bridge not too far upstream that didn’t add too much extra distance.</p>
<p>I don't remember too much about the next section other than us taking the path around two woods near Carn Dearg rather than following the route as plotted as by this stage we were very reluctant to try anything that wasn&#8217;t on a well defined path.</p>
<p>Reaching the River Feshie we were on the lookout for a place to cross as we knew there was no bridge other than the Pony Bridge that would add at least 10km. We ended up crossing at a wide section of the river approximately level with the <a href="https://www.mountainbothies.org.uk/bothies/eastern-highlands/ruigh-aiteachain/">Ruigh Aiteachain bothy</a>. It was thigh deep and fast flowing but we all managed to stay on our feet. On the other side of the river just needed to bash through some woods to reach a track that would take us up to the tent.</p>
<p>We reached the tent, and the 107km marker after 25 and a half hours on the go. We were all very tired and looking forward to a good breakfast followed by a nap in the tent, where we’d stashed some sleeping bags, gas stoves, food and clothes. On opening it we quickly found that it was filled with midges. Any thoughts of spending time here went out of the window – our relaxing stop was now a dash and grab with little more than a change of socks and a restock of food in our packs.</p>
<h3>Glenfeshie to Cairngorm YH</h3>
<p>Leaving Glenfeshie we immediately started climbing into the Cairgorms on a wide track. This continued for 4km until we reached a plateau lasting around 8km before the next climb up to Cairn Toul, the first 4000-footer since we&#8217;d left the Lochaber range yesterday morning. This should have been easy but fairly soon the path petered out and we were trying to find our way across more boggy ground. </p>
<p><img src="img/s4000-8.jpg" class="event-image"></p>
<p>Lack of sleep was really starting to take its toll by now and I was scanning the ground for somewhere to sleep as we were above the elevation at which midges are found. Nothing was forthcoming but eventually Mark and I collapsed on a bed of damp moss and almost immediately fell asleep. I&#8217;d set a timer for 20 minutes but very soon it started raining quite heavily and sleep became impossible. The countdown timer still had 12 minutes left so could only have been a little more than a 7 minute &#8220;power nap&#8221; but it made all the difference and I was certainly ready to get up to the next summit. Nav here was again a bit tricky and we realised part way up that we were heading up the wrong side of Angel’s Peak, fortunately we caught ourselves before we’d got to a point where we needed to backtrack and could just contour round.</p>
<p><img src="img/s4000-9.png" class="event-image"></p>
<p><br>Up at Cairn Toul (122km, 30h), we started to see the majesty of the Cairngorms and impressive views when there were gaps in the cloud. From here we had an easy path to follow taking in The Angel&#8217;s Peak, Carn na Criche, Einich Cairn and Braeriach before a very steep descent into the valley before Ben Macdui. This was one part of the route that I was quite worried about as it was going to be steep down and up with no path marked on the map. The descent wasn't too bad, with the initial bit done with feet, bums and hands and fairly quickly we were down at the stream and looking for the stream to follow up to Ben Macdui. </p>
<p><img src="img/s4000-10.jpg" class="event-image"></p>
<p>We'd read in a blog post that a sub-24-hour runner had managed the Ben Macdui ascent in 40 minutes so we knew it was going to be a lot longer than that for us. Hasan led the way and kept us moving. I monitored the elevation and rewarded us with a break each time we&#8217;d climbed another 100 metres. After 1h40 we made it to the top (134km, 35h). It hadn&#8217;t been that bad after all.</p>
<p><img src="img/s4000-11.png" class="event-image"></p>
<p>Now we just had a fairly easy run across to Cairgorm and then the final descent down to the youth hostel. It was looking like we&#8217;d be back in time for dinner &#8211; perfect!</p>
<p>Cairgorm was easy, although by this time I was starting to hallucinate quite a lot seeing signs in the distance that disappeared when you got closer. A little push and we were on the final summit (141km, 36h30).</p>
<p><img src="img/s4000-12.jpg" class="event-image"></p>
<p>It really was all downhill from here, 900m of descent, fairly steep to start with and then into the woods for the last few kilometres.</p>
<p><img src="img/s4000-13.png" class="event-image"></p>
<p>It was starting to go dark again and abruptly the trail petered out approximately 1km from the end. A few attempts at locating it ended in failure and we were starting to get worried as the undergrowth was dense making it difficult to make progress. My halluciations were now frequent and I kept seeing forestry signs that surely must be on a forestry track, but heading to each of these ended in failure as none of them were real. Now we were really getting worried as it was dark, we were lost, were heading to imaginary features and the midges were starting to attack. Ultimately, we decided to head back to pick up the road. This added another 6km and was hugely demotivating as there was now no chance of getting any food at the end, never mind a celebratory beer. In hindsight I'm pretty sure that we could have smashed our way through the woods for that final kilometre but turning back was the safest decision, as none of us were thinking rationally by then.</p>
<p>Finally we made it back to the youth hostel, cooked up a bit of chicken soup in the room and collapsed into bed. We'd made it. A total distance of 154km in 39h26.</p>
</full-page>
</template>

<script>

import FullPage from '@/components/page/FullPage';

export default {
    name: 'EventsPage',
    components: {
        FullPage
    },
};
</script>

<style scoped>

p {
    text-align: left;
    margin: 10px;
    font-size: 1.5rem;
}

h2 {
    margin: 10px;
    text-align: left;
    font-size: 2.0rem;
}

h3 {
    margin: 10px;
    text-align: left;
    font-size: 1.6rem;
}
.event-image {
    width: 400px;
    text-align: left;
}
</style>