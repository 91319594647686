<template>
    <full-page title="Future Events">
<h2>The Bob Graham Round</h2>        
<p>
    TODO...
</p>

<h2>The Coast-to-coast</h2>        
<p>
    TODO...
</p>
</full-page>
</template>

<script>

import FullPage from '@/components/page/FullPage';

export default {
    name: 'EventsPage',
    components: {
        FullPage
    },
};
</script>

<style scoped>

p {
    text-align: left;
    margin: 10px;
    font-size: 1.5rem;
}

h2 {
    margin: 10px;
    text-align: left;
    font-size: 2.0rem;
}
</style>